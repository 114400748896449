import axios from "../../configs/axios";
import env from '../../configs/env.json';

let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.monitoringEndpoint = `https://monitoring.${host}/api`
}
const domain = env.monitoringEndpoint;



export default {
    namespaced: true,
    state: {
        routes: {},
        areas: [],
        areaLogs: [],
        crossTrackingTasks: [],
        crossTrackingLogs: [],
    },
    getters: {
        routes: state => state.routes,
        areas: state => state.areas,
        crossTrackingTasks: state => state.crossTrackingTasks,
        crossTrackingLogs: state => state.crossTrackingLogs,
        areaLogs: state => state.areaLogs
    },
    actions: {


        createMonitoringTask({commit}, payload){
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.id
            if (!innerPayload.period){
                delete innerPayload.period
            }
            if (innerPayload.service_ids && innerPayload.service_ids.length === 0){
                delete innerPayload.service_ids
            }

            return new Promise((resolve, reject) => {
                axios({url: `/monitoring_task/${payload.id}`, data: innerPayload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createCrossTrackingTask({commit}, payload){

            return new Promise((resolve, reject) => {
                axios({url: 'cross_tracking_task', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },



        getTargetsLocations({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'target_locations', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setTargetsLocations', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getAreas({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'area_tracking_tasks', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setAreas', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getCrossTrackingTasks({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'cross_tracking_tasks',  baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setCrossTrackingTasks', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getCrossTrackingTasksLogs({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'cross_tracking_logs', data: payload,  baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setCrossTrackingLogs', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },





        targetLocationsPagination({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: 'target_locations', data: payload, baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setTargetsLocationsPagination', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        clearTargetsLocations({commit}){
            commit('setClearTargetsLocations')
        },
        createArea({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'area_tracking_task', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateArea({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `area_tracking_task/${payload.id}`, data: payload.payload,baseURL: domain, method: 'PUT' })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        areaTrackingLogs({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios({url: 'area_tracking_logs', data: payload,baseURL: domain, method: 'POST' })
                    .then(resp => {
                        commit('setAreasLogs', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },
    mutations: {
        setTargetsLocations(state , targetsLocations){
            state.routes = targetsLocations
        },
        setCrossTrackingTasks(state, crossTrackingTasks){
            state.crossTrackingTasks = crossTrackingTasks
        },
        setCrossTrackingLogs(state, crossTrackingLogs){
            state.crossTrackingLogs = crossTrackingLogs
        },
        setTargetsLocationsPagination(state, targetLocations){
            let existPoints = state.routes.data
            let allPoints = existPoints.concat(targetLocations.data)
            let routes = {
                count: state.routes.count + targetLocations.count,
                data: allPoints,
                total: targetLocations.total
            }
            state.routes = routes
        },
        setClearTargetsLocations(state){
            state.routes = []
        },
        setAreas(state, areas){
            state.areas = areas
        },
        setAreasLogs(state, logs){
            state.areaLogs = logs
        }
    }
}

