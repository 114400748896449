import Vue from 'vue';
import Vuex from 'vuex';
import contacts from "@/store/modules/contacts";
import ui from "@/store/modules/ui";
import auth from "@/store/modules/auth";
import users from "@/store/modules/users";
import images from "@/store/modules/images";
import monitoring from "@/store/modules/monitoring";
import source_groups from "@/store/modules/source_groups";
import service_groups from "@/store/modules/service_groups";
import stats from "@/store/modules/stats";
import translation from "@/store/modules/translation";
import nameSearchAdditionalSettings from "@/store/modules/nameSearchAdditionalSettings";
import mainMenuState from "@/store/modules/mainMenuState";
import grabbersStats from "@/store/modules/grabbersStats";
import billing from "@/store/modules/billing";
import logs from "@/store/modules/logs";
import full_search from "@/store/modules/full_search";
import tenants from "@/store/modules/tenants"
import search_rates from './modules/search_rates';
import movementHistory from "@/store/modules/movementHistory";
import locationMonitoring from "@/store/modules/locationMonitoring";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      contacts,
      ui,
      auth,
      users,
      images,
      monitoring,
      source_groups,
      service_groups,
      stats,
      translation,
      nameSearchAdditionalSettings,
      mainMenuState,
      grabbersStats,
      billing,
      logs,
      full_search,
      tenants,
      search_rates,
      movementHistory,
      locationMonitoring
   }
});